import React from "react";
import "halfmoon/css/halfmoon.min.css";
import { Footer } from "./Footer";
import { LogoHorizontal } from "./LogoHorizontal";

interface Props {}

export const Legal: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <div style={{ marginLeft: "2em", marginTop: "2em" }}>
        <LogoHorizontal height={"2em"} />
      </div>

      <div style={{ minHeight: "80vh", padding: "2em" }}>
      <iframe
          src="/tac.html"
          style={{
            width: "100%",
            height: "80vh",
            border: "none",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
          title="Privacy_policy"
          sandbox={""}
        />
      </div>
      <Footer />
    </div>
  );
};

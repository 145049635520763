import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCrown, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

interface User {
  name: string;
  host: boolean;
}

interface Props {
  users: Array<User>;
}

export const RoomInformation: React.FC<Props> = (props: Props) => {
  return (
    <div className="card connected-users-small">
      <h2 className="card-title font-size-16">Connected users</h2>
      {props.users.map((user: User, index: number) => {
        return (
          <div key={"user_" + index}>
            <span>
              <FontAwesomeIcon icon={faUser} style={{ marginRight: "8px" }} />
              {user.name}
              {user.host && (
                <FontAwesomeIcon
                  icon={faCrown}
                  style={{ marginLeft: "8px", color: "#FFC107" }}
                />
              )}
            </span>
            <hr></hr>
          </div>
        );
      })}
      <button
        className="btn btn-primary"
        type="button"
        onClick={() => {
          copyToClipboard(
            window.location.origin + "/" + localStorage.getItem("roomID")
          );
          toast.success(" Invitation link copied to Clipboard!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }}
      >
        <FontAwesomeIcon icon={faUserPlus} /> Invite
      </button>
    </div>
  );
};

/**
 * Copies the paramter string into your clipboard.
 * @param str The string you want to copy.
 */
const copyToClipboard = (str: string) => {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

import React, { useEffect, useRef, useCallback } from "react";
import YouTubePlayer from "youtube-player";
import "../style/videoPlayer.css";

interface Props {
  videoId: string;
  time?: number;
  startTime?: number;
  playing?: boolean;
  playbackRate?: number;
  playbackQuality?: string;
  onReady?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onEnd?: () => void;
  onStateChange?: (state: number) => void;
  onError?: (error: string) => void;
  onPlaybackQualityChange?: (quality: string) => void;
  onPlaybackRateChange?: (rate: number) => void;
  onTimeChange?: (time: number) => void;
  onTimeUpdate?: (time: number) => void;
}

export const YouTube: React.FC<Props> = (props: Props) => {
  const player = useRef<any>(null);
  const playerContainer = useRef<HTMLDivElement>(null);
  const expectedTime = useRef<number>(0);

  const {
    videoId,
    time,
    startTime,
    playing,
    playbackRate,
    playbackQuality,
    onTimeChange,
    onTimeUpdate,
  } = props;

  const isEqualMargin = (a: number, b: number, margin: number) =>
    Math.abs(a - b) < margin;

  const evalTimeChange = useCallback(() => {
    let interval = setInterval(async () => {
      let currentTime = await player.current.getCurrentTime();
      let pbRate = await player.current.getPlaybackRate();

      if (!isEqualMargin(expectedTime.current, currentTime, 0.8 * pbRate)) {
        if (onTimeChange) onTimeChange(currentTime);
      }

      if (onTimeUpdate) onTimeUpdate(currentTime);

      expectedTime.current = currentTime;
    }, 500);

    return () => clearInterval(interval);
  }, [onTimeChange, onTimeUpdate]);

  useEffect(() => {
    if (playerContainer.current && !player.current) {
      player.current = YouTubePlayer(playerContainer.current);

      player.current.loadVideoById(props.videoId, props.time);

      player.current.on("ready", (_: any) => {
        if (props.onReady) props.onReady();
        if (props.onTimeChange) evalTimeChange();
      });

      player.current.on("stateChange", (event: any) => {
        switch (event.data) {
          case 0:
            if (props.onEnd) props.onEnd();
            break;
          case 1:
            if (props.onPlay) props.onPlay();
            break;
          case 2:
            if (props.onPause) props.onPause();
            break;
        }

        if (props.onStateChange) props.onStateChange(event.data);
      });

      player.current.on("playbackQualityChange", (event: any) => {
        if (props.onPlaybackQualityChange) {
          props.onPlaybackQualityChange(event.data);
        }
      });

      player.current.on("playbackRateChange", (event: any) => {
        if (props.onPlaybackRateChange) {
          props.onPlaybackRateChange(event.data);
        }
      });

      player.current.on("error", (event: any) => {
        console.log(event.data);
        if (props.onError) props.onError(event.data);
      });
    }
  }, [props, evalTimeChange]);

  useEffect(() => {
    if (time) player.current.seekTo(time, true);
  }, [time]);

  useEffect(() => {
    if (playbackRate) player.current.setPlaybackRate(playbackRate);
  }, [playbackRate]);

  useEffect(() => {
    if (playbackQuality) player.current.setPlaybackQuality(playbackQuality);
  }, [playbackQuality]);

  useEffect(() => {
    if (videoId) player.current.loadVideoById(videoId, startTime || 0);
  }, [videoId, startTime]);

  useEffect(() => {
    if (playing !== undefined) {
      if (playing) {
        player.current.playVideo();
      } else {
        player.current.pauseVideo();
      }
    }
  }, [playing]);

  return (
    <div className="video-container">
      <div ref={playerContainer} />
    </div>
  );
};

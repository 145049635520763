import React, { useState, useEffect, useRef } from "react";
import { Startup } from "./Startup";
import { Room } from "./Room";
import endpointsConfig from "../apis/endpoints.config";

type Media = {
  readonly id: string;
  readonly title: string;
  readonly channel: string;
  readonly thumbnail: string;
};

type Data = {
  readonly action: string;
  readonly roomID: string;
  readonly media: Media;
  readonly connectionID: string;
  readonly time: number;
  readonly room: any;
  readonly playing: boolean;
  readonly queue: any;
  readonly timestamp: number;
  readonly rate: number;
  readonly chatMessage: string;
  readonly username: string;
  readonly code: number;
  readonly requester: string;
};

let ws: WebSocket;

export const Connected: React.FC<{}> = () => {
  //Initialize state variables
  const [mode, setMode] = useState("startup");
  const type = useState("youtube");
  const [videoID, setVideoID] = useState("FaWEv8bCSD0");
  const [time, setTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [rate, setRate] = useState(1);
  const [playing, setPlaying] = useState(true);
  const [users, setUsers] = useState([]);
  const [recent, setRecent] = useState<any[]>([]);
  const [queue, setQueue] = useState<any[]>([]);
  const [chatMessages, setChatMessages] = useState<any[]>([]);
  const [hostConnectionId, setHostConnectionId] = useState("");
  const [userConnectionId, setUserConnectionId] = useState("");
  const [videoTitle, setVideoTitle] = useState("Welcome to frnds.watch");
  const [videoChannel, setVideoChannel] = useState("frnds.watch");
  const [startupError, setStartupError] = useState("");

  const currentTime = useRef(0);
  const lastInput = useRef(Date.now());

  // Used for spam protection. 
  // Checks if the last input was 200ms or more ago
  const verifyInput = () => {
    if (Date.now() - lastInput.current > 50 || Date.now() - lastInput.current < 10) {
      lastInput.current = Date.now();
      return true;
    }
    //alert("Please slow down, too fast inputs can lead to synchronization errors.")
    lastInput.current = Date.now();
    return false;
  }

  useEffect(() => {
    //Initialize the websocket.    
    ws = new WebSocket(endpointsConfig.WS_URL);
  }, []);

  useEffect(() => {
    //Keep the socket alive
    ws.onopen = () => {
      sendMessage({ action: "login" });
      setInterval(() => {
        sendMessage({
          action: "keepAlive",
        });
      }, 30000);
    };

    //Handle incoming messages.
    ws.onmessage = (evt: MessageEvent) => {
      const data: Data = JSON.parse(evt.data);

      if(!verifyInput()) return;

      switch (data.action) {
        case "login":
          setUserConnectionId(data.connectionID);
          break;
        case "roomCreated":
          localStorage.setItem("roomID", data.roomID);
          setMode("video");
          sendMessage({
            action: "roomInfo",
            roomID: localStorage.getItem("roomID"),
          });
          window.history.pushState(null, "", window.location.origin + "/" + data.roomID);
          break;
        case "roomJoined":
          setMode("video");
          sendMessage({
            action: "roomInfo",
            roomID: localStorage.getItem("roomID"),
          });
          sendMessage({
            action: "requestSync",
            roomID: localStorage.getItem("roomID"),
          });
          break;
        case "roomLeft":
          sendMessage({
            action: "roomInfo",
            roomID: localStorage.getItem("roomID"),
          });
          break;
        case "roomInfo":

          setUsers(
            data.room.Items.map((item: any) => {
              if (item.host === true) setHostConnectionId(item.ID);
              return { name: item.username, host: item.host };
            })
          );
          break;
        case "load":
          setTime(0);
          setStartTime(0);
          setVideoID(data.media.id);
          setRecent((oldRecent: any[]) => [...oldRecent, data.media]);
          setVideoTitle(data.media.title);
          setVideoChannel(data.media.channel);
          setPlaying(true);
          break;
        case "play":
          setPlaying(true);
          break;
        case "pause":
          setPlaying(false);
          break;
        case "timeChange":
          setTime(data.time);
          break;
        case "playbackRateChange":
          setRate(data.rate);
          break;
        case "requestSync":
          sendMessage({
            action: "sync",
            media: {
              id: videoID,
              title: videoTitle,
              channel: videoChannel,
            },
            time: currentTime.current,
            playing,
            queue,
            rate,
            roomID: localStorage.getItem("roomID"),
            type,
            receiverID: data.requester
          });
          break;
        case "sync":
          setTime(data.time);
          setPlaying(data.playing);
          setStartTime(data.time);
          setVideoID(data.media.id);
          setVideoTitle(data.media.title);
          setVideoChannel(data.media.channel);
          setQueue(data.queue);
          setRate(rate);
          break;
        case "queueUpdate":
          setQueue(data.queue);
          break;
        case "chatMessage":
          setChatMessages((oldChatMessages) => [
            ...oldChatMessages,
            {
              message: data.chatMessage,
              username: data.username,
              connectionID: data.connectionID,
            },
          ]);
          break;
        case "roomError":
          switch (data.code) {
            case 401:
              setStartupError("Wrong password. You are not authorized to join this room.");
              break;
            case 404:
              setStartupError(
                "Room not found. Please enter a valid room or create a new one."
              );
              break;
            case 405:
              setStartupError(
                "Room code already exits. Choose other code or join this room."
              );
              break;
            default:
              setStartupError("Internal server error. Try again later.");
              break;
          }
          break;
        default:
          console.log("Unrecognized event: ", data);
          break;
      }
    };
  }, [queue, rate, playing, videoID, type, videoChannel, videoTitle]);

  /**
   * Sends a message to the websocket.
   * @param message The message object to send.
   */
  const sendMessage = (message: object) => {
    ws.send(JSON.stringify(message));
  };

  /**
   * Sends a chat message to the websocket.
   * @param message The message object to send.
   */
  const sendChatMessage = (chatMessage: string, username: string) => {
    sendMessage({
      action: "chatMessage",
      chatMessage,
      username,
      roomID: localStorage.getItem("roomID"),
    });
    setChatMessages([
      ...chatMessages,
      {
        self: true,
        message: chatMessage,
        username
      }
    ])
  };

  /**
   * Changes the currently played video.
   * @param id The id of the selected video.
   */
  const videoSelect = (
    id: string,
    title: string,
    channel: string,
    thumbnail: string
  ) => {
    sendMessage({
      action: "load",
      media: {
        id,
        title,
        channel,
        thumbnail,
      },
      type,
      roomID: localStorage.getItem("roomID"),
    });

    let anchor = document.getElementById("top-scroll-anchor");
    if (anchor) anchor.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  };

  /**
   * Called when the video player has finished loading.
   */
  const videoReady = () => {
    console.log("ready");
  };

  /**
   * Called when the user starts the video.
   */
  const videoPlay = () => {
    sendMessage({
      action: "play",
      roomID: localStorage.getItem("roomID"),
      type,
    });
  };

  /**
   * Called when the user pauses the video.
   */
  const videoPause = () => {
    sendMessage({
      action: "pause",
      roomID: localStorage.getItem("roomID"),
      type,
    });
  };

  /**
   * Called when the user changes the playback speed.
   */
  const playbackRateChange = (rate: number) => {
    sendMessage({
      action: "playbackRateChange",
      roomID: localStorage.getItem("roomID"),
      rate,
      type,
    });
  };

  /**
   * Called when a video finished playing
   */
  const videoFinished = () => {
    if (userConnectionId === hostConnectionId && queue.length >= 1) {
      let nextVideo = {
        id: queue[0].id,
        title: queue[0].title,
        channel: queue[0].channel,
        thumbnail: queue[0].thumbnail,
      };

      sendMessage({
        action: "load",
        media: nextVideo,
        roomID: localStorage.getItem("roomID"),
        type,
      });

      sendMessage({
        action: "queueUpdate",
        queue: queue.filter((item) => item.id !== nextVideo.id),
        roomID: localStorage.getItem("roomID"),
        type,
      });
    }
  };

  /**
   * Removes a video from the queue.
   */
  const removeFromQueue = (list_id: number) => {
    let queueCopy = queue;
    queueCopy.splice(list_id, 1);

    sendMessage({
      action: "queueUpdate",
      queue: queueCopy,
      roomID: localStorage.getItem("roomID"),
      type,
    });
  };

  /**
   * Adds a video to the queue.
   */
  const addToQueue = (
    id: string,
    title: string,
    channel: string,
    thumbnail: string
  ) => {
    let mediaObj = {
      id,
      title,
      channel,
      thumbnail,
    };

    sendMessage({
      action: "queueUpdate",
      queue: [...queue, mediaObj],
      roomID: localStorage.getItem("roomID"),
      type,
    });
  };

  /**
   * Called when the user changes the current time of the video.
   * @param time The new time of the video.
   */
  const videoTimeChange = (time: number) => {
    if (time && Math.abs(currentTime.current - time) > 0.5) {
      sendMessage({
        action: "timeChange",
        time,
        roomID: localStorage.getItem("roomID"),
        type,
      });
    }
  };

  /**
   * Called when video time changed. Usally every 500ms.
   * @param time The new time of the video.
   */
  const videoTimeUpdate = (time: number) => {
    currentTime.current = time;
  };

  /**
   * Creates a room.
   * @param username The username as which you join the room.
   */
  const roomCreate = (roomID: string, username: string, password: string) => {
    localStorage.setItem("username", username);
    sendMessage({ action: "createRoom", username, roomID, password });
  };

  /**
   * Joins a room.
   * @param roomID    The id of the room to join.
   * @param username  The username as which you join the room.
   */
  const roomJoin = (roomID: string, username: string, password: string) => {
    localStorage.setItem("roomID", roomID);
    localStorage.setItem("username", username);
    sendMessage({
      action: "joinRoom",
      username,
      roomID,
      password
    });
  };

  return (
    <div>
      {mode === "startup" && (
        <Startup
          onCreate={roomCreate}
          onJoin={roomJoin}
          errorMessage={startupError}
        />
      )}
      {mode === "video" && (
        <Room
          videoID={videoID}
          playing={playing}
          time={time}
          startTime={startTime}
          rate={rate}
          onReady={videoReady}
          onPause={videoPause}
          onPlay={videoPlay}
          onVideoFinished={videoFinished}
          onTimeChange={videoTimeChange}
          onTimeUpdate={videoTimeUpdate}
          users={users}
          onSearch={videoSelect}
          recentVideos={recent}
          queueVideos={queue}
          onQueueAdd={addToQueue}
          videoTitle={videoTitle}
          videoChannel={videoChannel}
          onQueueRemove={removeFromQueue}
          onPlaybackRateChange={playbackRateChange}
          userConnectionId={userConnectionId}
          chatMessages={chatMessages}
          onChatMessage={sendChatMessage}
        />
      )}
    </div>
  );
};

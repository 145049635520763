import React, { useEffect } from "react";
import { Connected } from "./components/Connected";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Legal } from "./components/Legal";
import { PrivacyPolicy } from "./components/PrivacyPolicy";

function App() {
  useEffect(() => {
    if (localStorage.getItem("dark-mode") !== null) {
      if (localStorage.getItem("dark-mode") === "true") {
        document.body.classList.add("dark-mode");
      }
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/terms-and-conditions">
            <Legal />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/:room?">
            <Connected />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import "halfmoon/css/halfmoon.min.css";
import "../style/startup.css";
import { Footer } from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router";
import { LogoHorizontal } from "./LogoHorizontal";

interface Props {
  onCreate: (roomId: string, username: string, password: string) => void;
  onJoin: (roomId: string, username: string, password: string) => void;
  errorMessage: string;
}

var halfmoon = require("halfmoon");

export const Startup: React.FC<Props> = (props: Props) => {
  let { room } = useParams<any>();
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordSet, setPasswordSet] = useState(false);
  const [mode, setMode] = useState(() => (room ? "join" : "create"));
  const [username, setUsername] = useState(
    localStorage.getItem("username") || makeUsername()
  );
  const [roomId, setRoomId] = useState(() => (room ? room : makeid(8)));
  const [password, setPassword] = useState("");

  useEffect(() => {
    ReactGA.pageview("/startup");
  }, []);

  useEffect(() => {
    halfmoon.onDOMContentLoaded();
  }, []);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  }, [props.errorMessage]);

  const _onSubmit = () => {
    if (username.length <= 0) {
      setErrorMessage("Please enter a username.");
      return;
    }

    if (!/^[A-Za-z0-9_-]*$/.test(roomId)) {
      setErrorMessage("A Room code can only contain letters and numbers");
      return;
    }

    if (roomId.length <= 0) {
      setErrorMessage("Please enter a Room code");
      return;
    }

    if (mode === "join") {
      props.onJoin(roomId, username, password);
    } else if (mode === "create") {
      props.onCreate(roomId, username, password);
    }

    setErrorMessage("");
  };

  return (
    <div>
      <div className="main-container side-image">
        <div className="startup-top">
          <LogoHorizontal height="2em" />
          <div>
            <span
              className={mode === "create" ? "mode mode-selected" : "mode"}
              onClick={() => setMode("create")}
            >
              Create
            </span>
            <span
              className={mode === "join" ? "mode mode-selected" : "mode"}
              onClick={() => setMode("join")}
            >
              Join
            </span>
          </div>
        </div>
        <div className="startup-main">
          <div className="startup-container">
            <h2 className="startup-heading">
              {mode === "create" ? "Create" : "Join"} a room
            </h2>
            {errorMessage.length !== 0 && (
              <div
                className="alert alert-danger"
                role="alert"
                style={{ marginBottom: "1rem" }}
              >
                <h4
                  className="alert-heading"
                  style={{ color: "#ff0204", margin: 0 }}
                >
                  {errorMessage}
                </h4>
              </div>
            )}
            <form
              onSubmit={(e: React.SyntheticEvent<EventTarget>) => {
                e.preventDefault();
                _onSubmit();
              }}
            >
              <label
                htmlFor="username-input"
                className="startup-label"
                data-toggle="tooltip"
                data-title="This is under what name your friends will see you."
              >
                Username
              </label>
              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Name"
                  id="username-input"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUsername(e.currentTarget.value)
                  }
                  value={username}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => setUsername(makeUsername())}
                  >
                    <FontAwesomeIcon icon={faSync} />
                  </button>
                </div>
              </div>

              <div style={{ margin: "0.2em" }}></div>

              {mode === "join" && (
                <div>
                  <label htmlFor="room-id" className="startup-label">
                    Room
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="room-id"
                    placeholder="Room"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setRoomId(e.currentTarget.value)
                    }
                    value={roomId}
                  />
                </div>
              )}
              <div className="custom-switch startup-switch">
                <input
                  type="checkbox"
                  id="password-switch"
                  checked={isPasswordSet}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPasswordSet(e.currentTarget.checked)
                  }
                />
                <label htmlFor="password-switch">Password</label>
              </div>
              {isPasswordSet && (
                <input
                  className="form-control"
                  type="password"
                  id="room-password"
                  placeholder="Password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.currentTarget.value)
                  }
                  value={password}
                />
              )}
              <hr style={{ marginTop: "1em" }}></hr>
              <button className="submit-button" type="submit">
                {mode === "join" ? "Join a room" : "Create room"}
              </button>
            </form>
          </div>
          <div className="startup-container">
            <InstructionItem
              number={1}
              title="Create a room"
              text="Create a room for you and your friends."
            />
            <div className="instruction-divider"></div>
            <InstructionItem
              number={2}
              title="Invite your friends"
              text="Invite your friends so they can join you."
            />
            <div className="instruction-divider"></div>
            <InstructionItem
              number={3}
              title="Enjoy videos together"
              text="Watch YouTube together and enjoy your time."
            />
          </div>
        </div>
        <div className="empty"></div>
      </div>
      <div className="attribution-container">
        <a
          href="https://www.freepik.com/free-vector/abstract-fluid-shapes-background_4650155.htm"
          target="_blank"
          rel="noreferrer"
        >
          Background created by pikisuperstar
        </a>
      </div>
      <Footer />
    </div>
  );
};

interface InstructionItemProps {
  number: number;
  text: string;
  title: string;
}

export const InstructionItem: React.FC<InstructionItemProps> = (
  props: InstructionItemProps
) => {
  return (
    <div className="instruction">
      <div>
        <div className="instruction-number">{props.number}</div>
      </div>
      <div>
        <span className="instruction-title">{props.title}</span>
        <span>{props.text}</span>
      </div>
    </div>
  );
};

/**
 * Creates a random id with a given length
 * @param length The desirec length.
 */
const makeid = (length: number) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * Creates a random username
 */
const makeUsername = () => {
  const firstNames = [
    "Amazing",
    "Beloved",
    "Bubba",
    "Cheeky",
    "Chunky",
    "Crazy",
    "Epic",
    "Fluffy",
    "Fruity",
    "Giggling",
    "Peachy",
    "Pretty",
    "Yummy",
    "Mc",
    "Lil",
  ];
  const lastNames = [
    "Unicorn",
    "Bulldog",
    "Alligator",
    "Wolf",
    "Barnacle",
    "Beaver",
    "Boogle",
    "Cat",
    "Jellyfish",
    "Seal",
  ];
  return (
    firstNames[Math.floor(Math.random() * firstNames.length)] +
    lastNames[Math.floor(Math.random() * lastNames.length)]
  );
};

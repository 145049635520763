import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import ClampLines from "react-clamp-lines";
import "../style/startup.css";
import "../style/videoList.css";
import "../style/videoPreview.css";

interface ItemProps {
  thumbnail: string;
  title: string;
  channel: string;
  link: string;
  list_id: number;
  onSelect: (
    id: string,
    title: string,
    channel: string,
    thumbnail: string
  ) => void;
  onQueueRemove: (list_id: number) => void;
}

export const VideoListItem: React.FC<ItemProps> = (props: ItemProps) => {
  return (
    <div className="video-list-container">
      <div
        className="video-list-item"
        onClick={() =>
          props.onSelect(
            props.link,
            props.title,
            props.channel,
            props.thumbnail
          )
        }
      >
        <div className="video-thumbnail-wrapper">
          <div
            className="video-thumbnail"
            style={{
              backgroundImage: 'url("' + props.thumbnail + '")',
            }}
          />
        </div>
        <div>
          <ClampLines
            text={props.title}
            id="video_title"
            lines={2}
            ellipsis="..."
            buttons={false}
            innerElement="p"
            className="video-title"
          />
          <ClampLines
            text={props.channel}
            id="video_channel"
            lines={1}
            ellipsis="..."
            buttons={false}
            innerElement="span"
            className="video-channel"
          />
        </div>
      </div>
      <div
        onClick={() => props.onQueueRemove(props.list_id)}
        className="video-list-remove"
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <hr />
    </div>
  );
};

interface ItemPropsHistory {
  thumbnail: string;
  title: string;
  channel: string;
  link: string;
  onSelect: (
    id: string,
    title: string,
    channel: string,
    thumbnail: string
  ) => void;
}

export const VideoListItemHistory: React.FC<ItemPropsHistory> = (
  props: ItemPropsHistory
) => {
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() =>
        props.onSelect(props.link, props.title, props.channel, props.thumbnail)
      }
    >
      <div className="video-list-item">
        <div className="video-thumbnail-wrapper">
          <div
            className="video-thumbnail"
            style={{
              backgroundImage: 'url("' + props.thumbnail + '")',
            }}
          />
        </div>

        <div>
          {props.title && (
            <ClampLines
              text={props.title}
              id="video_title"
              lines={2}
              ellipsis="..."
              buttons={false}
              innerElement="p"
              className="video-title"
            />
          )}
          {props.channel && (
            <ClampLines
              text={props.channel}
              id="video_channel"
              lines={1}
              ellipsis="..."
              buttons={false}
              innerElement="span"
              className="video-channel"
            />
          )}
        </div>
      </div>
      <hr />
    </div>
  );
};

interface Props {
  videoQueue: Array<Object>;
  recentlyPlayed: Array<Object>;
  onSearch: (
    id: string,
    title: string,
    channel: string,
    thumbnail: string
  ) => void;
  onQueueRemove: (list_id: number) => void;
}

export const VideoList: React.FC<Props> = (props: Props) => {
  const [mode, setMode] = useState("queue");

  return (
    <div className="card connected-users-small">
      <div className="mode-selector" style={{ marginTop: 0 }}>
        <span
          className={mode === "queue" ? "mode mode-selected" : "mode"}
          onClick={() => setMode("queue")}
        >
          Queue
        </span>
        <span
          className={mode === "recent" ? "mode mode-selected" : "mode"}
          onClick={() => setMode("recent")}
        >
          History
        </span>
      </div>

      {mode === "queue" && (
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {props.videoQueue.length === 0 && <p>No videos in your queue.</p>}
          {props.videoQueue.map((video: any, index: number) => {
            return (
              <VideoListItem
                key={index}
                title={video.title}
                channel={video.channel}
                thumbnail={video.thumbnail}
                link={video.id}
                onSelect={props.onSearch}
                list_id={index}
                onQueueRemove={props.onQueueRemove}
              />
            );
          })}
        </div>
      )}

      {mode === "recent" && (
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {props.recentlyPlayed.length === 0 && <p>No recent videos</p>}
          {props.recentlyPlayed.map((video: any, index: number) => {
            return (
              <VideoListItemHistory
                key={index}
                title={video.title}
                channel={video.channel}
                thumbnail={video.thumbnail}
                link={video.id}
                onSelect={props.onSearch}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faGhost } from "@fortawesome/free-solid-svg-icons";
import { RoomInformation } from "./RoomInformation";
import { VideoSearch } from "./VideoSearch";
import {decode} from "html-entities";
import youtube from "../apis/youtube";
import "halfmoon/css/halfmoon.min.css";
import "../style/room.css";
import { VideoList } from "./VideoList";
import endpointsConfig from "../apis/endpoints.config";
import { Footer } from "./Footer";
import { Chat } from "./Chat";
import { LogoHorizontal } from "./LogoHorizontal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { YouTube } from "./YouTube";

interface User {
  name: string;
  host: boolean;
}

interface Props {
  videoID: string;
  time: number;
  startTime: number;
  rate: number;
  playing: boolean;
  users: Array<User>;
  onReady: () => void;
  onPlay: () => void;
  onPause: () => void;
  onVideoFinished: () => void;
  onTimeChange: (time: number) => void;
  onTimeUpdate: (time: number) => void;
  onPlaybackRateChange: (rate: number) => void;
  onSearch: (
    id: string,
    title: string,
    channel: string,
    thumbnail: string
  ) => void;
  onQueueAdd: (
    id: string,
    title: string,
    channel: string,
    thumbnail: string
  ) => void;
  onQueueRemove: (list_id: number) => void;
  recentVideos: Array<Object>;
  queueVideos: Array<Object>;
  videoTitle: string;
  videoChannel: string;
  userConnectionId: string;
  chatMessages: Array<Object>;
  onChatMessage: (message: string, username: string) => void;
}

var halfmoon = require("halfmoon");

export const Room: React.FC<Props> = (props: Props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [streamerMode, setStreamerMode] = useState(() => {
    if (localStorage.getItem("streamerMode") === null) {
      localStorage.setItem("streamerMode", "false");
      return false;
    }
    return localStorage.getItem("streamerMode") === "true";
  });

  useEffect(() => {
    ReactGA.pageview("/room");
  }, []);

  /**
   * Handle the search request.
   * If search query is a link extract videoId and open.
   * Otherwise perform a search on YouTube.
   */
  const _onSearch = async () => {
    let response: any;

    //Check if search query is a valid URL.
    if (validURL(searchQuery)) {
      //Extract the videoId and open video.
      let videoID = getVideoIdFromURL(searchQuery);
      response = await youtube.get("/videos", {
        params: {
          part: "snippet",
          maxResults: 9,
          id: videoID,
          key: endpointsConfig.YOUTUBE_API_KEY,
        },
      });
    } else {
      //Perform a search request to the YouTube API
      response = await youtube.get("/search", {
        params: {
          part: "snippet",
          maxResults: 9,
          q: searchQuery,
          key: endpointsConfig.YOUTUBE_API_KEY,
        },
      });
    }

    response.data.items.forEach((item: any) => {
      item.snippet.channelTitle = decode(item.snippet.channelTitle);
      item.snippet.description = decode(item.snippet.description);
      item.snippet.title = decode(item.snippet.title);
    });    

    setSearchResults(response.data.items);    

    let resultsElem = document.getElementById("resultsScrollPoint");

    if(resultsElem) resultsElem.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  };

  useEffect(() => {
    if (streamerMode) {
      window.history.pushState(null, "", window.location.origin);
    } else {
      window.history.pushState(
        null,
        "",
        window.location.origin + "/" + localStorage.getItem("roomID")
      );
    }
  }, [streamerMode]);

  useEffect(() => {
    halfmoon.onDOMContentLoaded();
  }, []);

  const _toggleDarkMode = () => {
    halfmoon.toggleDarkMode();

    if (document.body.classList.contains("dark-mode")) {
      localStorage.setItem("dark-mode", "true");
    } else {
      localStorage.setItem("dark-mode", "false");
    }
  };

  return (
    <div>
      <ToastContainer autoClose={8000} />
      <div className="page-wrapper with-navbar">
        <nav className="navbar">
          <a href="/" className="navbar-brand">
            <LogoHorizontal height={"2.5rem"} />
          </a>

          <form
            className="form-inline ml-auto"
            onSubmit={(e: React.SyntheticEvent<EventTarget>) => {
              e.preventDefault();
              _onSearch();
            }}
          >
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search YouTube or enter video link"
                style={{ width: "30vw" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchQuery(e.currentTarget.value)
                }
              />
              <div className="input-group-append">
                <button className="btn btn-primary search-button" type="submit">
                  Search
                </button>
              </div>
            </div>
          </form>

          <div className="d-none d-md-flex ml-auto">
            <button
              className="btn dark-mode-button"
              type="button"
              style={{
                marginRight: "8px",
                border: "none",
                color: streamerMode ? "#E91E63" : "inherit",
              }}
              onClick={() => {
                setStreamerMode(!streamerMode);
              }}
            >
              <FontAwesomeIcon icon={faGhost} />
            </button>
            <button
              className="btn dark-mode-button"
              type="button"
              style={{
                marginRight: "8px",
                border: "none",
              }}
              onClick={_toggleDarkMode}
            >
              <FontAwesomeIcon icon={faMoon} />
            </button>
          </div>
        </nav>
        <div className="content-wrapper container-fluid" id="top-scroll-anchor">
          <div className="row" style={{minHeight: "100vh"}}>
            <div className="col-lg-9">
              <div className="content">
                <YouTube
                  videoId={props.videoID}
                  playing={props.playing}
                  time={props.time}
                  startTime={props.startTime}
                  playbackRate={props.rate}
                  onPause={props.onPause}
                  onPlay={props.onPlay}
                  onEnd={props.onVideoFinished}
                  onTimeChange={props.onTimeChange}
                  onTimeUpdate={props.onTimeUpdate}
                  onPlaybackRateChange={props.onPlaybackRateChange}
                />
              </div>
              <div className="card">
                <h2
                  className="font-size-18"
                  style={{
                    fontWeight: "bold",
                    margin: 0,
                    marginBottom: "0.2em",
                  }}
                >
                  {props.videoTitle}
                </h2>
                <p style={{ margin: 0 }}>{props.videoChannel}</p>
              </div>
              <div className="card" id="resultsScrollPoint">
                <h2 className="card-title font-size-16">Search results</h2>
                <VideoSearch
                  searchResults={searchResults}
                  onSearch={props.onSearch}
                  onQueueAdd={props.onQueueAdd}
                />
              </div>
              <div className="mobile-only">
                <RoomInformation users={props.users} />
                <VideoList
                  videoQueue={props.queueVideos}
                  recentlyPlayed={props.recentVideos}
                  onSearch={props.onSearch}
                  onQueueRemove={props.onQueueRemove}
                />
                <Chat
                  userConnectionId={props.userConnectionId}
                  chatMessages={props.chatMessages}
                  onChatMessage={props.onChatMessage}
                  ident={"messageContainerMobile"}
                />
              </div>
            </div>
            <div className="col-lg-3 d-none d-lg-block">
              <RoomInformation users={props.users} />
              <VideoList
                videoQueue={props.queueVideos}
                recentlyPlayed={props.recentVideos}
                onSearch={props.onSearch}
                onQueueRemove={props.onQueueRemove}
              />
              <Chat
                userConnectionId={props.userConnectionId}
                chatMessages={props.chatMessages}
                onChatMessage={props.onChatMessage}
                ident={"messageContainer"}
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

/**
 * Get the URL parameters
 * source: https://css-tricks.com/snippets/javascript/get-url-variables/
 * @param  {String} url The URL
 * @return {Object}     The URL parameters
 */
var getParams = function (url: string) {
  var params = {};
  var parser = document.createElement("a");
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    (params as any)[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};

function getVideoIdFromURL(url: string) {
  let videoId = "";
  let params = getParams(url);

  if ((params as any).v) {
    videoId = (params as any).v;
  }

  if(url.includes("youtu.be")) {
    videoId = url.split("/")[3];
  }

  return videoId;
}


/**
 * Checks if a URL string is a valid URL.
 * @param {String} str The url string.
 */
function validURL(str: string) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

import React, { useEffect, useState } from "react";
import "../style/chat.css";

interface Props {
  userConnectionId: string;
  chatMessages: Array<Object>;
  onChatMessage: (message: string, username: string) => void;
  ident: string;
}

export const Chat: React.FC<Props> = (props: Props) => {
  const [msg, setMsg] = useState("");
  const [showSB, setShowSB] = useState(false);

  const scrollToEnd = () =>{
    let msgCont = document.getElementById(props.ident);
    if (msgCont) {
      msgCont.scrollTo({
        top: msgCont.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
    setShowSB(false);
  }

  const checkIfBottom = () =>{
    let msgCont = document.getElementById(props.ident);
    if (msgCont) {
      //TODO fix
      if (msgCont.scrollTop >= (msgCont.scrollHeight - msgCont.offsetHeight)) {
          setShowSB(false);
      }
    }
  }

  useEffect(() => {
    let msgCont = document.getElementById(props.ident);
    if (msgCont) {
      //TODO fix
      if (msgCont.scrollTop >= (msgCont.scrollHeight - msgCont.offsetHeight) - 100) {
        msgCont.scrollTo({
          top: msgCont.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }else{
        setShowSB(true);
      }
    }
  }, [props.chatMessages, props.ident]);

  return (
    <div>
      <div className="card connected-users-small">
        <h2 className="card-title font-size-16">Chat</h2>
        <div
          style={{
            minHeight: "50px",
            maxHeight: "200px",
            overflowY: "auto",
            padding: "1em",
          }}
          id={props.ident}
          onScroll={() => checkIfBottom()}
        >
          {props.chatMessages.length === 0 && <p>No messages yet.</p>}
          {props.chatMessages.map((message: any, index: number) => {
            return (
              <div
                className={
                  "message-" +
                  (message.self
                    ? "right"
                    : "left")
                }
                key={index}
              >
                <p className="message-user">{message.username}</p>
                <p className="message-data">{message.message}</p>
              </div>
            );
          })}
        </div>
        <form
          className="form-inline ml-auto"
          style={{position: "relative"}}
          onSubmit={(e: React.SyntheticEvent<EventTarget>) => {
            e.preventDefault();
            if(msg !== ""){
              props.onChatMessage(
                msg,
                localStorage.getItem("username") || "Herobrine"
              );
            }
            setMsg("");
          }}
        >
          {showSB && (
            <button className="new-messages-btn" onClick={(e: any) => { e.preventDefault(); scrollToEnd();}}>
              New messages
            </button>
          )}
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Message...."
              value={msg}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setMsg(e.currentTarget.value)
              }
              style={{ width: "30vw" }}
            />
            <div className="input-group-append">
              <button className="btn btn-primary search-button" type="submit">
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

import React from "react";
import ClampLines from "react-clamp-lines";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListUl } from "@fortawesome/free-solid-svg-icons";
import "../style/videoPreview.css";

interface Props {
  title: string;
  channel: string;
  thumbnail: string;
  link: string;
  onSelect: (
    id: string,
    title: string,
    channel: string,
    thumbnail: string
  ) => void;
  onQueueAdd: (
    id: string,
    title: string,
    channel: string,
    thumbnail: string
  ) => void;
}

export const VideoPreview: React.FC<Props> = (props: Props) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{ cursor: "pointer" }}
        onClick={() =>
          props.onSelect(
            props.link,
            props.title,
            props.channel,
            props.thumbnail
          )
        }
      >
        <div className="video-thumbnail-wrapper">
          <div
            className="video-thumbnail"
            style={{ backgroundImage: "url('" + props.thumbnail + "')" }}
          ></div>
        </div>
        <div>
          <ClampLines
            text={props.title}
            id="video_title"
            lines={2}
            ellipsis="..."
            buttons={false}
            innerElement="p"
            className="video-title"
          />
          <ClampLines
            text={props.channel}
            id="video_channel"
            lines={1}
            ellipsis="..."
            buttons={false}
            innerElement="span"
            className="video-channel"
          />
        </div>
      </div>
      <button
        className="btn btn-primary"
        onClick={() =>
          props.onQueueAdd(
            props.link,
            props.title,
            props.channel,
            props.thumbnail
          )
        }
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          lineHeight: "normal",
          height: "auto",
          padding: "4px",
        }}
      >
        <FontAwesomeIcon icon={faListUl} />
      </button>
    </div>
  );
};

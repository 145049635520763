import React from "react";
import logo_horizontal from "../images/logo_horizontal.svg";
import logo_horizontal_dark from "../images/logo_horizontal_dark.svg";
import logo_small from "../images/logo_small.svg";
import "../style/logo.css";

interface Props {
  height: string;
}

export const LogoHorizontal: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <img
        src={logo_horizontal}
        className="logo"
        style={{ height: props.height }}
        alt="logo_horizontal"
      />
      <img
        src={logo_horizontal_dark}
        className="logo-dark"
        style={{ height: props.height }}
        alt="logo_horizontal_dark"
      />
      <img
        src={logo_small}
        className="logo-small"
        style={{ height: props.height }}
        alt="logo_small"
      />
    </div>
  );
};

import React from "react";
import { VideoPreview } from "./VideoPreview";
import "../style/videoSearch.css";

interface Props {
  searchResults: Array<Object>;
  onSearch: (
    id: string,
    title: string,
    channel: string,
    thumbnail: string
  ) => void;
  onQueueAdd: (
    id: string,
    title: string,
    channel: string,
    thumbnail: string
  ) => void;
}

export const VideoSearch: React.FC<Props> = (props: Props) => {
  return (
    <div>
      {props.searchResults.length === 0 && <p>No results found</p>}
      <div className="video-grid">
        {props.searchResults.map((video: any, index: number) => {
          return (
            <VideoPreview
              key={index}
              title={video.snippet.title}
              channel={video.snippet.channelTitle}
              thumbnail={video.snippet.thumbnails.high.url}
              link={
                video.id.videoId === undefined ? video.id : video.id.videoId
              }
              onSelect={props.onSearch}
              onQueueAdd={props.onQueueAdd}
            />
          );
        })}
      </div>
    </div>
  );
};

import React from "react";
import "halfmoon/css/halfmoon.min.css";
import "../style/footer.css";
import { LogoHorizontal } from "./LogoHorizontal";
import pkg from "../../package.json";

interface Props {}

export const Footer: React.FC<Props> = (props: Props) => {

  const year = new Date().getFullYear()

  return (
    <div className="custom-footer">
      <div className="container-fluid">
        <div className="row row-eq-spacing-lg">
          <div className="col-lg-3">
            <div className="content">
              <div className="mb-10">
                <LogoHorizontal height={"2.5rem"} />
              </div>
              <div>
                <p style={{ margin: 0, fontSize: "0.6em" }}>Version {pkg.version}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="content">
              <h4 className="content-title font-size-16 mb-10">Contact</h4>
              <div>
                <a
                  href="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAMAANa4cQJUMklINTJFT1RMWUwxMU9KTE5BNzhNUkdTTC4u"
                  rel="noopener"
                  className="custom-footer-link"
                >
                  Report issue
                </a>
              </div>
              <div>
                <a
                  href="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAMAANa4cQJUNUJGSUs0NUVGU0w2QzI2UklETDdIM0RaQi4u"
                  rel="noopener"
                  className="custom-footer-link"
                >
                  Feedback
                </a>
              </div>
              <div>
                <a
                  href="mailto:maximittel@outlook.de?Subject=Contact"
                  rel="noopener"
                  className="custom-footer-link"
                >
                  Contact
                </a>
              </div>
              <div>
                <a
                  href="https://www.paypal.me/maximittel"
                  rel="noopener"
                  className="custom-footer-link"
                >
                  Support
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="content">
              <h4 className="content-title font-size-16 mb-10">Legal</h4>
              <div>
                <a href="/terms-and-conditions" className="custom-footer-link">
                  Terms and conditions
                </a>
              </div>
              <div>
                <a href="/privacy-policy/" className="custom-footer-link">
                  Privacy policy
                </a>
              </div>
              <div>
                <span className="custom-footer-link" onClick={() => (globalThis as any).displayPreferenceModal()}>Manage Cookie Preferences</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="content">
              <div className="mb-10">
                <a
                  href="#top"
                  className="btn btn-primary btn-scroll-to-top"
                  role="button"
                >
                  Scroll to top
                </a>
              </div>
              <div className="text-muted">
                © Copyright {year}, Maximilian Mittelhammer
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
